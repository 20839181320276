.customRow {
  display: flex;
  gap: 10px;
  padding-top: 75px;
  padding-bottom: 30px;
}
.column_1,.column_2 {
  padding: 10px;
  width: 50%;
}
.column_2 {
  text-align: left;
}
.column_1 img { 
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .customRow {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
  }
  .column_2, .column_1 {
    width: 100%;
  }
  .column_1 img { 
    width: 100%;
    height: 400px;
  }
}