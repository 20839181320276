.nav {
    background-color: #cf053c !important;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
}

.nav img {
    width: 10%;
}
.navLinks {
    display: flex;
    align-items: center;
    gap: 80px;
    padding: 30px;
}
a.nav-link {
    color: white;
    width: 90%;
}

button.navbar-toggler.collapsed {
    border: none;
}

.navLinks a:hover {
    text-decoration: underline;
}
.me-auto.navbar-nav {
    display: flex;
    gap: 30px;
    width: 100%;
}
div#basic-navbar-nav {
    width: 50%;
}
button.navbar-toggler.collapsed {
    border: none;
}

@media only screen and (max-width: 770px) {
    button.navbar-toggler.collapsed, .navbar-toggler:hover {
        display: contents;
    }
    .nav img {
        width: 20%;
    }
  }