.SingleImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.SingleImageContainer img {
  width: 90%;
  height: 500px;
  object-fit: cover;
}
.bottom {
  margin-bottom: 100px;
}

@media only screen and (max-width: 590px) {
  .SingleImageContainer {
    flex-direction: column;
    width: 90%;
    gap: 10px;
    align-items: center;
  }
  .SingleImageContainer img {
    width: 90%;
  }
}
