.heroVideo {
    width: 100vw;
    padding-top: 90px;
    height: 80vh;
}
video {
    object-fit: cover;
    width: 100%;
    height: 60vh;
}


@media only screen and (max-width: 590px) {
    .heroVideo {
        padding-top: 75px;
        height: 75vh;
    }
  }