.TwoImagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 75%;
}
.TwoImagesContainer img {
  width: 48%;
}
.top {
    margin-top: 20px;
    margin-bottom: 20px;
}
.pContainer {
    width: 75%;
}

@media only screen and (max-width: 590px) {
    .TwoImagesContainer {
        flex-direction: column;
        width: 90%;
        gap: 10px;
        align-items: center;
      }
      .TwoImagesContainer img {
        width: 95%;
        height: 400px;
      }
  }