.imageGroupContainerVertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 75%;
}
.imageGroupContainerVertical img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.imageGroupContainerVertical p {
  margin-top: 10px;
}

@media only screen and (max-width: 990px) {
    .imageGroupContainerVertical {
        width: 90%;
        gap: 10px;
        align-items: center;
      }
      .imageGroupContainerVertical img {
        width: 95%;
        height: 400px;
      }
  }