.heroTextContainer {
    display: flex;
    justify-content: space-between;
}
.left {
    width: 60%;
}
ul {
    list-style-type: none;
    padding-left: 0;
    text-align: right;
  }
  .heroTextContainer a {
    text-decoration: none;
    color: #cf053c;
    transition: 0.7s;
}

.heroTextContainer a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 990px) {
    .heroTextContainer {
      flex-direction: column;
      margin-bottom: 20px;
    }
    .left {
        width: 100%;
  }
  ul {
    text-align: left;
  }
}